import { useQuery } from '@tanstack/vue-query';

import { useMaintenanceWrapper } from '@/composables';
import { HttpClient } from '@/utils';
import { API_URL } from '@/utils/url';
import { GetCampaignListResponse } from '@/server/api/common/campaign/list.get';

/**
 *  キャンペーン一覧取得APIを呼び出すComposable
 */
export const useGetCampaignList = () => {
  const { queryFnWrapper } = useMaintenanceWrapper();

  const query = useQuery(['getCampaignList'], {
    queryFn: queryFnWrapper(async () => {
      const client = new HttpClient();
      return await client.get<GetCampaignListResponse>(API_URL.GET_CAMPAIGN_LIST);
    }),
    useErrorBoundary: true,
    enabled: false,
  });

  return query;
};
