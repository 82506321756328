<script setup lang="ts">
import { TopPage } from '@/functions/top/views';

import { useSeoMeta, useHead } from '#imports';

useSeoMeta({
  title: 'dスマホローン | カードがいらないドコモのカードローン',
  description:
    'dスマホローンは、ドコモが提供するスマホ完結のローンサービスです。カードがいらないカードローン。お申込みからご契約、お借入れ・ご返済もアプリまたはWebにて完結。ドコモの回線やその他ドコモのサービスのご利用状況に応じて最大年率3.0％も金利優遇！',
  keywords: 'dスマホローン,ドコモ',
  ogUrl: 'https://loan.docomo.ne.jp/',
  ogType: 'website',
  ogTitle: 'dスマホローン | カードがいらないドコモのカードローン',
  ogDescription:
    'dスマホローンは、ドコモが提供するスマホ完結のローンサービスです。カードがいらないカードローン。お申込みからご契約、お借入れ・ご返済もアプリまたはWebにて完結。ドコモの回線やその他ドコモのサービスのご利用状況に応じて最大年率3.0％も金利優遇！',
  ogSiteName: 'dスマホローン | ドコモ',
  ogImage: 'https://loan.docomo.ne.jp/img/ogp.png',
});

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://loan.docomo.ne.jp/',
    },
    {
      rel: 'icon',
      href: '/img/favicon.ico',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/img/android-chrome-192x192.png',
    },
    {
      rel: 'apple-touch-icon',
      href: '/img/apple-touch-icon.png',
    },
  ],
});
</script>

<template>
  <TopPage />
</template>

<style scoped lang="scss"></style>
