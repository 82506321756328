<script setup lang="ts">
import { Header } from '@/components/ui';
import { Footer } from '@/components/ui';
import { UiFullLoader } from '@/components/ui';
import { convertUrl } from '@/utils/convertUrl';
import { UsecaseOutput as InfoUsecase } from '@/functions/common/info/controllers/cms_contents/getInfoListUsecase';
import { useGetCampaignList } from '@/functions/top/views/composables';
import { CampaignCarousel, OtherServiceCarousel } from './components';

import { useGetInfoList } from './composables';
import { computed, ref, watch } from '#imports';

const { data: infoData, isLoading: infoListLoading } = useGetInfoList();
const { data: campaignList, refetch: refetchCampaignList, isLoading: campaignListLoading } = useGetCampaignList();
const infoListRef = ref<InfoUsecase>();
const maintenanceListRef = ref<InfoUsecase>();

const allApiLoading = computed(() => {
  return infoListLoading.value || campaignListLoading.value;
});

const createInfoList = (array: InfoUsecase): InfoUsecase => {
  // メンテナンス情報の除外
  const infoList = array.filter(({ maintenance_flag }) => {
    return !maintenance_flag;
  });

  // 日付降順ソート
  const list = infoList.sort((firstItem, secondItem) => {
    if (!firstItem.date || !secondItem.date) {
      return 0;
    }

    if (firstItem.date < secondItem.date) {
      return 1;
    }
    if (firstItem.date > secondItem.date) {
      return -1;
    }
    return 0;
  });

  // 5件のみ返却
  return list.slice(0, 5);
};

const createMaintenanceList = (array: InfoUsecase): InfoUsecase => {
  const maintenanceList = ['system-maintenance', 'emergency', 'attention', 'emergency02', 'emergency03'];
  // メンテナンスの抽出
  const list = array.filter(({ maintenance_flag }) => {
    return maintenance_flag;
  });
  return list.sort(
    (firstItem, secondItem) =>
      maintenanceList.indexOf(firstItem.id as string) - maintenanceList.indexOf(secondItem.id as string)
  );
};

watch(
  infoData,
  async () => {
    if (infoData.value) {
      // お知らせ情報
      infoListRef.value = createInfoList(infoData.value);

      // メンテナンス情報
      maintenanceListRef.value = createMaintenanceList(infoData.value);

      await refetchCampaignList();
    }
  },
  { immediate: true }
);

const getJapaneseDate = (hyphenatedDate: string) => {
  const date = hyphenatedDate.split('-');
  return `${date[0]}年${date[1]}月${date[2]}日`;
};

const APPLY_CONDITIONS =
  '優遇金利の適用条件について詳しくは<a class="arrow_icon arrow_icon02" href="/borrow/benefit/">こちら</a>';
const D_CARD_CASHING =
  'ｄカードのキャッシングをご利用中のお客さまへの注意事項は<a class="arrow_icon arrow_icon02" href="/apply/#anc_02">こちら</a>';
</script>
<template>
  <Header />
  <UiFullLoader v-if="allApiLoading" />
  <template v-else>
    <div class="isp hbg_bg"></div>

    <div class="imp_notice">
      <ul class="notice_list">
        <li v-for="(item, itemIndex) in maintenanceListRef" :key="itemIndex" class="notice_list_item">
          <a class="pointer_link" :href="`/info/${item.id}`"
            ><p>{{ item.title as string }}</p></a
          >
        </li>
      </ul>
    </div>
    <!-- /.imp_notice -->

    <main class="main">
      <h1 class="top_mv">
        <div class="notice_area_img mt0">
          <img src="/img/mv_img_01.png" alt="dスマホローン" width="980" height="380" class="isPc" />
          <img src="/img/mv_img_02.png" alt="dスマホローン" width="374" height="371" class="isSp" />
        </div>
      </h1>

      <div class="section_inr02 pt0 pb0">
        <ul class="listType01 mt10">
          <li v-html="APPLY_CONDITIONS"></li>
        </ul>
      </div>

      <div class="top_btn cv_btnArea">
        <div class="top_btnInr">
          <div class="top_btnColumn">
            <div class="top_btnItem">
              <p class="btnType02 btn_icon btn_icon01">
                <a
                  :href="`${convertUrl()}common/login/cushionWithUniversalLinkActivation?prompt=relogin&transitionSourceId=basic`"
                  >今すぐお申込み<br /><span>（dアカウントでログイン）</span></a
                >
              </p>
              <ol class="listType01">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <li v-html="D_CARD_CASHING"></li>
              </ol>
            </div>
            <p class="top_btnItem btnType03 btn_icon btn_icon04">
              <a href="/apply/1second/"><span>お借入可能？優遇金利は何％？</span>簡単1秒診断</a>
            </p>
          </div>
        </div>
      </div>
      <!-- /.top_btn -->

      <div class="slider slider01">
        <CampaignCarousel :campaign-list />
      </div>
      <!-- /.slider -->

      <div class="section_inr04">
        <div class="baseMt06">
          <p class="btnType01"><a href="/campaign/">キャンペーン一覧</a></p>
        </div>
      </div>

      <section class="section02 bgRed">
        <div class="section_inr01">
          <h2 class="top_title_lv2 fcRed tAc">
            <span class="ask">dスマホローンって</span><span class="ask">どんなサービス？</span>
          </h2>
          <p class="textType01 sptAl pctAc">
            dスマホローンはドコモが提供するスマホ完結のローンサービスです。<br />カードがいらないカードローン。<br />ドコモの回線契約がない方もお申込みいただけます。
          </p>
          <ul class="grit_link_area mt30">
            <li class="grit_item grit_item_01">
              <a class="grit_item_link pickup arrow_icon" href="/about/">
                <div class="grit_item_img">
                  <picture>
                    <img src="/img/img_38.svg" alt="dスマホローンとは" width="48" height="56" />
                  </picture>
                </div>
                <p class="grit_item_txt"><span class="ask">dスマホローン</span><span class="ask">とは</span></p>
              </a>
            </li>
            <li class="grit_item grit_item_02">
              <a class="grit_item_link arrow_icon" href="/apply/">
                <div class="grit_item_img">
                  <picture>
                    <img src="/img/img_40.svg" alt="お申込みについて" width="50" height="50" />
                  </picture>
                </div>
                <p class="grit_item_txt"><span class="ask">お申込みに</span><span class="ask">ついて</span></p>
              </a>
            </li>
            <li class="grit_item grit_item_03">
              <a class="grit_item_link arrow_icon" href="/borrow/">
                <div class="grit_item_img">
                  <picture>
                    <img src="/img/img_39.svg" alt="お借入れについて" width="52" height="54" />
                  </picture>
                </div>
                <p class="grit_item_txt"><span class="ask">お借入れに</span><span class="ask">ついて</span></p>
              </a>
            </li>
            <li class="grit_item grit_item_04">
              <a class="grit_item_link arrow_icon" href="/repay/">
                <div class="grit_item_img">
                  <picture>
                    <img src="/img/img_41.svg" alt="ご返済について" width="52" height="54" />
                  </picture>
                </div>
                <p class="grit_item_txt"><span class="ask">ご返済に</span><span class="ask">ついて</span></p>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <!-- /.section02 -->

      <section class="section03">
        <div class="section_inr02 pcMt40 pt0 sp_Prl0 bgDeepRed">
          <div class="top_point_area">
            <h2 class="title_lv2 fcWhite top_point_title">
              <span class="ask">dスマホローンの</span><span class="ask emphasis">4つのポイント</span>
            </h2>
            <ul class="top_point_anc anclink">
              <li>
                <a class="top_point_ancItem" href="#point1">
                  <div class="top_point_icon">
                    <span class="top_point_point">POINT</span><span class="top_point_num">1</span>
                  </div>
                  <p><span class="ask">最大年率3.0％も</span><span class="ask">金利優遇！</span></p>
                </a>
              </li>
              <li>
                <a class="top_point_ancItem" href="#point2">
                  <div class="top_point_icon">
                    <span class="top_point_point">POINT</span><span class="top_point_num">2</span>
                  </div>
                  <p><span class="ask">アプリ</span><span class="ask">・Webで</span><span class="ask">完結！</span></p>
                </a>
              </li>
              <li>
                <a class="top_point_ancItem" href="#point3">
                  <div class="top_point_icon">
                    <span class="top_point_point">POINT</span><span class="top_point_num">3</span>
                  </div>
                  <p>少額から<br /><span class="ask">お借入れ</span><span class="ask">できる！</span></p>
                </a>
              </li>
              <li>
                <a class="top_point_ancItem" href="#point4">
                  <div class="top_point_icon">
                    <span class="top_point_point">POINT</span><span class="top_point_num">4</span>
                  </div>
                  <p>
                    <span class="ask">d払いで</span><span class="ask">すぐに</span><span class="ask">使える！</span>
                  </p>
                </a>
              </li>
            </ul>
            <ul class="listType01">
              <li class="fcWhite">ご契約には審査があります。</li>
            </ul>
            <ul class="top_pointArea pcMt25 spMt20">
              <li id="point1" class="top_point_item">
                <div class="top_point_itemInr">
                  <div class="top_point_textArea">
                    <div class="top_point_column">
                      <div class="top_point_icon">
                        <span class="top_point_point">POINT</span><span class="top_point_num">1</span>
                      </div>
                      <h3 class="top_point_tll txtL01 alignSelfC">
                        <span class="ask"
                          ><span class="ask">ドコモのサービスの</span><span class="ask">ご利用状況に応じて</span></span
                        ><span class="ask"
                          ><span class="ask"
                            ><span class="marker"
                              >最大年率<span class="fcRed"><span class="txtL03">3.0</span>％も</span></span
                            ></span
                          ><span class="ask"
                            ><span class="marker"><span class="txtL02 fcRed">金利優遇！</span></span></span
                          ></span
                        >
                      </h3>
                    </div>
                    <p class="textType01">ドコモの回線やその他対象サービスのご契約・ご利用で金利がおトクに！</p>
                  </div>
                  <div class="top_point_img isPc">
                    <img
                      src="/img/top_img_02.svg"
                      alt="ドコモのサービスのご利用状況に応じて最大年率3.0％も金利優遇！"
                      width="173"
                      height="144"
                    />
                  </div>
                </div>
                <div class="point-rateArea mt20">
                  <div class="point-rateArea-inner bgPink">
                    <p class="txtL01 alignSelfC fwb txtL01 mAuto">
                      優遇適用後金利 <br class="isSp" /><span class="fcRed"
                        ><span class="txtL02">0.9</span>％～<span class="txtL02">17.9</span>％</span
                      ><span class="txtL01 fcRed ask">（実質年率）</span>
                    </p>
                  </div>
                  <div class="point-rateArea-inner">
                    <p class="txtL01 alignSelfC fwb txtL01 mAuto">
                      <span class="ask"
                        >基準金利 <br class="isSp" /><span class="txtL02">3.9</span>％～<span class="txtL02">17.9</span
                        >％<span class="ask"><span class="txtL01 ask">（実質年率）</span></span></span
                      >
                    </p>
                  </div>
                </div>
                <div class="top_point_img isSp">
                  <img
                    src="/img/top_img_02.svg"
                    alt="ドコモのサービスのご利用状況に応じて最大年率3.0％も金利優遇！"
                    width="173"
                    height="144"
                  />
                </div>
                <div class="top_point_btnArea">
                  <p class="btnType01"><a href="/borrow/benefit/">優遇金利の適用条件</a></p>
                </div>
              </li>
              <li id="point2" class="top_point_item">
                <div class="top_point_itemInr">
                  <div class="top_point_textArea">
                    <div class="top_point_column">
                      <div class="top_point_icon">
                        <span class="top_point_point">POINT</span><span class="top_point_num">2</span>
                      </div>
                      <h3 class="top_point_tll txtL01 alignSelfC">
                        <span class="ask"
                          ><span class="ask">お申込みからご契約、</span
                          ><span class="ask">お借入れ・ご返済も</span></span
                        ><span class="ask"
                          ><span class="txtL02 fcRed"
                            ><span class="ask"
                              ><span class="marker">アプリ・Web<span class="txtL01">で</span></span></span
                            ><span class="ask"><span class="marker">完結！</span></span></span
                          ></span
                        >
                      </h3>
                    </div>
                    <p class="textType01">面倒な郵送手続きは一切不要！<br />原則ご自宅への郵送物もありません。</p>
                  </div>
                  <div class="top_point_img">
                    <img
                      src="/img/top_img_01.svg"
                      alt="お申込みからご契約、お借入れ・ご返済もアプリ・Webで完結！"
                      width="250"
                      height="195"
                    />
                  </div>
                </div>
                <div class="top_point_btnArea">
                  <p class="btnType01"><a href="/about/flow/">お申込みからご返済までの流れ</a></p>
                </div>
              </li>
              <li id="point3" class="top_point_item">
                <div class="top_point_itemInr">
                  <div class="top_point_textArea">
                    <div class="top_point_column">
                      <div class="top_point_icon">
                        <span class="top_point_point">POINT</span><span class="top_point_num">3</span>
                      </div>
                      <h3 class="top_point_tll txtL01 alignSelfC">
                        <span class="ask">必要なときに必要な分だけ</span
                        ><span class="ask"
                          ><span class="ask"
                            ><span class="marker txtL02 fcRed">少額<span class="txtL01">から</span></span></span
                          ><span class="ask"><span class="marker txtL02 fcRed">お借入れできる！</span></span></span
                        >
                      </h3>
                    </div>
                    <p class="textType01">
                      d払い残高へのお借入れは1,000円から（※）、<br
                        class="isPc"
                      />金融機関口座へのお借入れは10,000円からご利用可能！
                    </p>
                  </div>
                  <div class="top_point_img">
                    <img
                      src="/img/top_img_03.svg"
                      alt="必要なときに必要な分だけ少額からお借入れできる！"
                      width="240"
                      height="145"
                    />
                  </div>
                </div>
                <div class="top_point_btnArea">
                  <p class="btnType01"><a href="/borrow/">お借入方法について</a></p>
                </div>
              </li>
              <li id="point4" class="top_point_item">
                <div class="top_point_itemInr">
                  <div class="top_point_textArea">
                    <div class="top_point_column">
                      <div class="top_point_icon">
                        <span class="top_point_point">POINT</span><span class="top_point_num">4</span>
                      </div>
                      <h3 class="top_point_tll txtL01 alignSelfC">
                        <span class="ask"
                          ><span class="ask">d払い残高にお借入れしたら</span
                          ><span class="ask">普段お使いいただいている</span></span
                        ><span class="ask"
                          ><span class="marker txtL02 fcRed"
                            ><span class="ask">d払い<span class="txtL01">で</span></span
                            ><span class="ask">すぐに使える！</span></span
                          ></span
                        ><span class="notes_icon pl_0">（※）</span>
                      </h3>
                    </div>
                    <p class="textType01">お支払いや日頃のお買物もさらに便利に！</p>
                  </div>
                  <div class="top_point_img">
                    <img
                      src="/img/top_img_04.svg"
                      alt="d払い残高にお借入れしたら普段お使いいただいているd払いですぐに使える！"
                      width="239"
                      height="149"
                    />
                  </div>
                </div>
                <div class="top_point_btnArea">
                  <p class="btnType01"><a href="/borrow/d_payment/">d払い残高にお借入れ</a></p>
                </div>
              </li>
              <li class="top_point_item">
                <ul class="listType01">
                  <li>
                    d払い残高をご利用いただく場合は、d払いアプリのダウンロード、d払い残高の利用登録および本人確認が必要です。(d払い残高種別が「現金バリュー」の方のみご利用いただけます。）<br />
                    <a
                      class="outLink outLink02"
                      href="https://service.smt.docomo.ne.jp/keitai_payment/guide/app_ekyc_auth.html"
                      target="_blank"
                      rel="noopener"
                      >d払いの本人確認とは</a
                    >
                  </li>
                </ul>
                <br />
                <p class="btnType01 top_point_btn btn_icon btn_icon03">
                  <a href="/about/">dスマホローンについて<br />もっと詳しく見る</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="section_inr02">
          <div class="top_btn cv_btnArea mt0">
            <div class="top_btnColumn">
              <div class="top_btnItem">
                <p class="btnType02 btn_icon btn_icon01">
                  <a
                    :href="`${convertUrl()}common/login/cushionWithUniversalLinkActivation?prompt=relogin&transitionSourceId=basic`"
                    >今すぐお申込み<br /><span>（dアカウントでログイン）</span></a
                  >
                </p>
                <ol class="listType01">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <li v-html="D_CARD_CASHING"></li>
                </ol>
              </div>
              <p class="top_btnItem btnType03 btn_icon btn_icon04">
                <a href="/apply/1second/"><span>お借入可能？優遇金利は何％？</span>簡単1秒診断</a>
              </p>
            </div>
          </div>
          <!-- /.top_btn" -->
        </div>
      </section>
      <!-- /.section03 -->

      <section class="bgBule">
        <div class="section_inr01">
          <h2 class="title_lv2">お知らせ</h2>
          <div class="top_info info_area">
            <div class="info_area_inr js_info_top">
              <ul class="info_list">
                <li v-for="(item, itemIndex) in infoListRef" :key="itemIndex" class="info_list_item">
                  <a class="info_link arrow_icon" :href="`/info/${item.id}`">
                    <p v-if="item.important_flag" class="info_date imp_icon">
                      {{ getJapaneseDate(item.date as string) }}
                    </p>
                    <p v-else class="info_date">{{ getJapaneseDate(item.date as string) }}</p>
                    <p class="info_txt">{{ item.title as string }}</p>
                  </a>
                </li>
              </ul>
            </div>
            <!-- /.info_area_inr -->
            <div class="linkbtn01">
              <p class="btnType01"><a href="/info/">お知らせ一覧</a></p>
            </div>
          </div>
        </div>
      </section>
      <!-- /.bgBule -->

      <section class="section03">
        <div class="section_inr01">
          <h2 class="title_lv2">よくあるご質問</h2>
          <p class="textType01">お客さまからお問い合わせの多いご質問をご案内しております。</p>
          <br />
          <ul class="faq lr_noBorder" itemscope itemtype="https://schema.org/FAQPage">
            <li class="faq_item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
              <input type="checkbox" id="faq_01" class="acc_head" />
              <label for="faq_01" class="faq_head acc_icon" itemprop="name">
                申込みに必要な書類を教えてください。
              </label>
              <div class="faq_body acc_body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <p class="faq_text" itemprop="text">
                  本人確認書類および収入証明書類をご用意ください。<br />
                  お申込みに必要な書類について詳しくは<a href="/apply/docs/"><u>こちら</u></a>
                </p>
              </div>
            </li>
            <li class="faq_item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
              <input type="checkbox" id="faq_02" class="acc_head" />
              <label for="faq_02" class="faq_head acc_icon" itemprop="name">
                金利優遇される条件を教えてください。
              </label>
              <div class="faq_body acc_body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <div class="faq_text">
                  <p itemprop="text">
                    優遇金利の適用条件については、以下をご確認ください。<br />
                    優遇金利について詳しくは<a href="/borrow/benefit/"><u>こちら</u></a>
                  </p>
                </div>
              </div>
            </li>
            <li class="faq_item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
              <input type="checkbox" id="faq_03" class="acc_head" />
              <label for="faq_03" class="faq_head acc_icon" itemprop="name">
                申込みから契約完了までに、どのくらい時間がかかりますか？
              </label>
              <div class="faq_body acc_body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                <p class="faq_text" itemprop="text">
                  最短即日でご契約いただけます。なお、審査内容やお申込みいただく時間によって異なります。
                </p>
              </div>
            </li>
          </ul>
          <div class="linkbtn01">
            <p class="btnType01"><a href="/faq/">よくあるご質問をもっと見る</a></p>
          </div>
        </div>
      </section>
      <!-- /.section03 -->

      <section class="bgBule">
        <div class="section_inr02">
          <h2 class="title_lv2">dスマホローンのお役立ち記事</h2>
          <p class="textType01 article_desc">dスマホローンがお金に関するお役立ち情報をお届けします。</p>

          <ul class="article_list">
            <li class="article_item">
              <a href="/article/column004/">
                <img src="/img/article004_thumbnail.jpg" alt="カードローンをイメージした画像" />
                <p>
                  <span class="arrow_icon">カードローンとは？知っておきたい特徴や申込み方法、利用時の注意点を解説</span>
                </p>
              </a>
            </li>
            <li class="article_item">
              <a href="/article/column009/">
                <img src="/img/article009_thumbnail.jpg" alt="カードローンの審査をイメージした画像" />
                <p>
                  <span class="arrow_icon">カードローンの審査基準とは？利用の流れや必要書類についてもご紹介</span>
                </p>
              </a>
            </li>
            <li class="article_item">
              <a href="/article/column010/">
                <img src="/img/article010_thumbnail.jpg" alt="キャッシングローンの利用を考える女性" />
                <p>
                  <span class="arrow_icon"
                    >キャッシングとカードローンは同じもの？それぞれの違いやメリットなどを解説</span
                  >
                </p>
              </a>
            </li>
          </ul>

          <div class="linkbtn01">
            <p class="btnType01"><a href="/article/">dスマホローンのお役立ち記事</a></p>
          </div>
        </div>
      </section>
      <!-- /.bgBule -->

      <ul class="cv_card cv_btnArea">
        <li class="cv_card_item">
          <h2 class="title_lv2">新規お申込み</h2>
          <div class="cv_card_text_area">
            <p class="cv_card_text pctAl sptAc">
              <span class="ask"><span class="emphasis">お申込み</span>から<span class="emphasis">ご契約</span>まで</span
              ><span class="ask"><span class="emphasis">Web<span>で</span>完結！</span></span>
            </p>
          </div>
          <div class="cv_card_btn01">
            <p class="top_btnItem btnType02 btn_icon btn_icon01 cv_card_btn fwb">
              <a
                :href="`${convertUrl()}common/login/cushionWithUniversalLinkActivation?prompt=relogin&transitionSourceId=basic`"
                >今すぐお申込み<br /><span>（dアカウントでログイン）</span></a
              >
            </p>
            <ul class="listType01 tAl mt10">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <li v-html="D_CARD_CASHING"></li>
            </ul>
          </div>
        </li>
        <li class="cv_card_item">
          <h2 class="title_lv2">簡易シミュレーション</h2>
          <div class="cv_card_btn02">
            <p class="top_btnItem btnType03 btn_icon btn_icon04 cv_card_btn fwb">
              <a href="/apply/1second/"><span>お借入可能？優遇金利は何％？</span>簡単1秒診断</a>
            </p>
          </div>
          <div class="cv_card_btn02 mt10">
            <p class="top_btnItem btnType03 btn_icon btn_icon02 cv_card_btn fwb">
              <a href="/borrow/simulation/"
                ><span>毎月のご返済額はいくらぐらい？</span
                ><span class="fs18"><span class="ask">ご返済</span><span class="ask">シミュレーション</span></span></a
              >
            </p>
          </div>
        </li>
      </ul>

      <div class="mt40">
        <h2 class="title_lv2">ドコモグループのその他融資サービス</h2>
        <ul class="mw885 dispFlex jcSB mAuto flexDirColumn">
          <li>
            <a
              href="https://www.orixcredit.co.jp/orixmoney/omatome/?id=82002T02O0BM8C102406"
              class="mw285 dispBlock mAuto"
              target="_blank"
              rel="noopener"
              ><img src="/img/occ_omatome_banner_241016.png" alt="オリックス・クレジット おまとめローン"
            /></a>
          </li>
          <li class="sp-mt15">
            <a
              href="https://www.orixcredit.co.jp/loan/vip/?id=53OK011999"
              class="mw285 dispBlock mAuto"
              target="_blank"
              rel="noopener"
              ><img src="/img/occ_vip_banner.png" alt="オリックス・クレジット VIPローンカード"
            /></a>
          </li>
          <li class="sp-mt15">
            <a
              href="https://www.orixcredit.co.jp/loan/vipbusiness/?id=53OK001999"
              class="mw285 dispBlock mAuto"
              target="_blank"
              rel="noopener"
              ><img
                src="/img/occ_vipbusiness_banner.png"
                alt="オリックス・クレジット VIPローンカード　BUSINESS"
                width="285"
                height="107"
            /></a>
          </li>
        </ul>
      </div>

      <div class="slider mb40 slider02">
        <h2 class="title_lv2">その他ドコモのサービス</h2>
        <OtherServiceCarousel />
      </div>
      <!-- /.slider -->

      <div class="mb40">
        <h2 class="title_lv2">ドコモのネットショッピング</h2>
        <ul class="mw585 dispFlex jcSB mAuto flexDirColumn">
          <li>
            <a
              href="https://dshopping.docomo.ne.jp/feature/event/dshoppingselect/?utm_source=d_loan&utm_medium=free-display&utm_campaign=dshopping_20230915_dshoppingselect_free-display"
              class="mw285 dispBlock mAuto"
              target="_blank"
              rel="noopener"
              ><img
                src="/img/dshoppingbanner.png"
                alt="毎週水曜更新 日常使いが商品が毎日おトク！ dショッピングセレクト"
            /></a>
          </li>
          <li class="sp-mt15">
            <a
              href="https://dfashion.docomo.ne.jp/top/index/-?utm_medium=free-display&utm_source=loan&utm_campaign=dfashion_202207_dftop_loantop"
              class="mw285 dispBlock mAuto"
              target="_blank"
              rel="noopener"
              ><img src="/img/dfashion_banner_20220708.png" alt="dファッション" width="285" height="107"
            /></a>
          </li>
        </ul>
      </div>

      <div class="mb40">
        <h2 class="title_lv2">d払いが使えるネットショッピング</h2>
        <a
          href="https://dshopping-3ple.docomo.ne.jp/?utm_source=loan&utm_medium=owned&utm_campaign=202311"
          class="mw285 dispBlock mAuto"
          target="_blank"
          rel="noopener"
        >
          <img src="/img/dnetshoppingbanner.png" alt="サンプル百貨店 話題のあれこれ半額以下" />
        </a>
      </div>
    </main>
    <!-- /.main -->

    <Footer />
  </template>
</template>
<style scoped lang="scss">
@use '@/functions/top/views/css/slick.css';

.imp_notice {
  max-width: 980px;
  margin: 0 auto;
  padding: 20px 16px;
}

.notice_list > li a {
  color: #cc0033;
  font-size: 14px;
  line-height: 1.7;
  text-decoration: underline;
}

.notice_list > li {
  position: relative;
  margin-top: 10px;
  -webkit-text-decoration: -2em;
  text-decoration: -2em;
  padding-left: 2em;
}

.notice_list > li:first-child {
  margin-top: 0;
}

.notice_list > li::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(/img/img_42.svg) 0 0 no-repeat;
}

@media (min-width: 768px) {
  .notice_list > li {
    -webkit-text-decoration: -1.7em;
    text-decoration: -1.7em;
    padding-left: 1.7em;
  }
}

/* メンテナンスエリアのカーソル */
.pointer_link {
  cursor: pointer;
}

/* お知らせエリアのカーソル */
.info_link {
  cursor: pointer;
}
</style>
