<script setup lang="ts">
import { computed } from 'vue';

import { UiCarousel } from '@/components/ui';
import { UiCarouselItemProps } from '@/components/ui/carousel/UiCarouselItem.vue';

// その他ドコモサービスのリスト
const otherServiceList = [
  {
    href: 'https://denki.docomo.ne.jp/pages/202403-newlifecp.html?utm_source=d_sploan&amp;utm_medium=owned&amp;utm_campaign=docomo-denki_202403_denki_banner&amp;utm_content=denki-0043',
    src: '/img/electricity_banner_20240419.png',
    alt: 'ドコモでんき',
  },
  {
    href: 'https://hoken-navi.docomo.ne.jp/lk/ldp/car/003?utm_source=dsumaholoanbaner&utm_medium=referral&utm_campaign=dcm',
    src: '/img/insurance_banner_20220727.png',
    alt: '自動車保険',
  },
  {
    href: 'https://d-card.jp/std/campaigns/dcard_payment2006/cpn-dcard_payment2006.html?utm_source=dcard_service&utm_medium=free-display&utm_campaign=dcard_202207_dsl',
    src: '/img/kakei_banner_20220819.png',
    alt: '家計まるごとdカード支払いキャンペーン',
  },
  {
    href: 'https://dsmartbank.docomo.ne.jp/?utm_source=d_sploan&utm_medium=owned&utm_campaign=dsmartbank_202306_ohterservice&utm_term=SB00003&utm_content=event_kinou',
    src: '/img/dsmartbank_banner_20230627.png',
    alt: 'デジタル口座サービス',
  },
  {
    href: 'https://dpoint-inv.smt.docomo.ne.jp/portal/top?campaign_id=dsl001&utm_source=fintech_dsmartphone-loan&utm_medium=free-display&utm_campaign=fintech-dsmartphone-loan_nm_portal_20230915_01_dsl001&utm_content=dsl001',
    src: '/img/pointinvestment_banner_20231222_1.png',
    alt: 'ポイント投資',
  },
];

const itemsRef = computed(() =>
  otherServiceList
    ? otherServiceList.map((i): UiCarouselItemProps => {
        return {
          to: i.href,
          src: i.src,
          alt: i.alt,
          target: '_blank',
        };
      })
    : []
);
</script>
<template>
  <!-- 開催中のキャンペーンが1つの場合はスライドしない -->
  <div v-if="otherServiceList?.length === 1" class="slider_inr">
    <ul class="slider_list">
      <li class="slider_item">
        <a :href="itemsRef[0].to" target="_blank" rel="noopener"
          ><img :src="itemsRef[0].src" :alt="itemsRef[0].alt" width="285" height="107"
        /></a>
      </li>
    </ul>
  </div>
  <div v-else class="slider_inr">
    <ul class="slider_list slick-initialized slick-slider">
      <div class="slide_prev slick-arrow"></div>
      <div class="slick-list draggable">
        <UiCarousel v-if="otherServiceList" :items="itemsRef" class="slick-track" />
      </div>
      <div class="slide_next slick-arrow"></div>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@use '@/functions/top/views/css/slick.css';
</style>
