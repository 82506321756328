import { useQuery } from '@tanstack/vue-query';

import { HttpClient } from '@/utils';
import { API_URL } from '@/utils/url';
import { GetInfoListResponse } from '@/server/api/infoList/index.get';

/**
 *  お知らせ情報を取得する
 */
export const useGetInfoList = () => {
  return useQuery(['InfoList'], {
    queryFn: async (): Promise<GetInfoListResponse> => {
      const client = new HttpClient();
      return await client.get<GetInfoListResponse>(API_URL.GET_INFO_LIST);
    },
  });
};
