<script setup lang="ts">
import { computed } from 'vue';

import { UiCarousel } from '@/components/ui';
import { UiCarouselItemProps } from '@/components/ui/carousel/UiCarouselItem.vue';
import { UsecaseOutput } from '@/functions/common/campaign/controllers/getCampaignListUsecase';
import { ref, watch } from '#imports';

const props = withDefaults(
  defineProps<{
    campaignList?: UsecaseOutput;
  }>(),
  {
    campaignList: undefined,
  }
);

const campaignListRef = ref<UsecaseOutput>();

const createCampaignList = (array: UsecaseOutput): UsecaseOutput => {
  // 終了しているキャンペーンの除外
  const list = array.filter(({ end_flg }) => {
    return !end_flg;
  });

  return list.sort((a, b) => {
    // display_priority_order が null の場合を後ろに配置
    if (a.display_priority_order === null) return 1;
    if (b.display_priority_order === null) return -1;

    // display_priority_order を数値に変換して比較
    const orderA = Number(a.display_priority_order);
    const orderB = Number(b.display_priority_order);

    if (orderA === orderB) {
      // display_priority_order が同じ場合はidで降順
      const idB = b.id as string;
      const idA = a.id as string;
      return idB.localeCompare(idA);
    }
    return orderA - orderB; // display_priority_orderで昇順
  });
};

watch(
  props,
  () => {
    if (props.campaignList) {
      // バナー画像を表示するキャンペーンの取得
      campaignListRef.value = createCampaignList(props.campaignList);
    }
  },
  { immediate: true }
);

const FALLBACK_SRC = 'https://placehold.jp/570x166.png?text=X';
const FALLBACK_ALT = 'キャンペーン';

const itemsRef = computed(() =>
  campaignListRef.value
    ? campaignListRef.value.map((i): UiCarouselItemProps => {
        return {
          to: `/campaign/${i.detail_path}`,
          src: i.banner_image_reference || FALLBACK_SRC,
          alt: i.title || FALLBACK_ALT,
          target: '_self',
        };
      })
    : []
);
</script>
<template>
  <!-- 開催中のキャンペーンが1つの場合はスライドしない -->
  <div v-if="campaignListRef?.length === 1" class="slider_inr">
    <ul class="slider_list">
      <li class="slider_item">
        <a :href="itemsRef[0].to"><img :src="itemsRef[0].src" :alt="itemsRef[0].alt" width="285" height="107" /></a>
      </li>
    </ul>
  </div>
  <div v-else class="slider_inr">
    <ul class="slider_list slick-initialized slick-slider">
      <div class="slide_prev slick-arrow"></div>
      <div class="slick-list draggable">
        <UiCarousel v-if="campaignList" :items="itemsRef" class="slick-track" />
      </div>
      <div class="slide_next slick-arrow"></div>
    </ul>
  </div>
</template>
<style scoped lang="scss">
@use '@/functions/top/views/css/slick.css';
</style>
